import { HStack, VStack, Text } from '@chakra-ui/react';
import { useAddress } from '@thirdweb-dev/react';
import React, { FC } from 'react';

import { DebouncedInput } from '@app/components/DebouncedInput';
import { TokenSelector } from '@app/components/Swap/components/TokenSelector';
import { TokensPrice } from '@app/components/Swap/components/TokensPrice';
import { TokenBalanceInfo } from '@app/components/TokenBalanceInfo';
import { Token } from '@app/types/token';

interface Props {
  label: string;
  onTokenSelect: (val: Token) => void;
  disabled: boolean;
  onChange?: (val: string) => void;
  values: {
    token: Token | null;
    amount: string;
  };
  showMax?: boolean;
  source: 'from' | 'to';
  readOnlyValue?: string;
  disableSelect?: boolean;
  filterByPools?: boolean;
}

export const TokenInput: FC<Props> = ({
  onTokenSelect,
  disabled,
  onChange,
  values,
  showMax,
  source,
  readOnlyValue,
  label,
  disableSelect,
  filterByPools
}) => {
  const address = useAddress();

  return (
    <VStack
      w="100%"
      bg="neutral.1000"
      transition="background 0.2s ease"
      px="16px"
      py="12px"
      borderRadius="12px"
      overflow="hidden"
    >
      <Text
        textAlign="left"
        w="100%"
        color="neutral.300"
        fontSize="12px"
        lineHeight="18px"
        letterSpacing="-0.18px"
        mb="8px"
      >
        {label}
      </Text>
      <HStack w="100%" mb="4px">
        <TokenSelector
          onSelect={onTokenSelect}
          disabled={disabled || !!disableSelect}
          selected={values.token}
          filterByPools={filterByPools}
        />
        <DebouncedInput
          amount={values.amount}
          disabled={disabled}
          onChange={onChange}
          readOnlyValue={readOnlyValue}
        />
      </HStack>
      <HStack w="100%" justify="space-between">
        <HStack justify="flex-start">
          <Text
            color="neutral.500"
            fontSize="12px"
            fontWeight="400"
            lineHeight="18px"
            letterSpacing="-0.18px"
          >
            Balance:
          </Text>
          {values.token?.contractAddress && address ? (
            <TokenBalanceInfo address={values.token.contractAddress} />
          ) : (
            'n/a'
          )}
        </HStack>
        <TokensPrice
          value={readOnlyValue ?? values.amount}
          tokenAddress={values.token?.contractAddress}
          symbol={values.token?.symbol}
        />
      </HStack>
    </VStack>
  );
};
