import { Button } from '@chakra-ui/react';
import { useSwitchChain } from '@thirdweb-dev/react';
import React from 'react';
import { useToggle } from 'react-use';

import { getActiveChain } from '@app/constants/chains';
import { useNetworkMismatch } from '@app/hooks/thirdweb/useNetworkMismatch';

const activeChain = getActiveChain();

export const NetworkMismatchButton = () => {
  const [switching, setSwitching] = useToggle(false);
  const isMismatchedNetwork = useNetworkMismatch();
  const switchChain = useSwitchChain();

  if (!isMismatchedNetwork) {
    return null;
  }

  return (
    <Button
      variant="secondary"
      transition="all 0.2s ease"
      _hover={{ background: 'orange', color: 'white' }}
      w="100%"
      isLoading={switching}
      loadingText="Processing..."
      onClick={async () => {
        try {
          setSwitching(true);
          await switchChain(activeChain.chainId);
        } catch (e) {
          setSwitching(false);
        }
      }}
    >
      Switch network
    </Button>
  );
};

export default NetworkMismatchButton;
